import React from 'react'
import PropTypes from 'prop-types'
import NumberBoxStyled from './styledComponent'

class NumberBox extends React.PureComponent {
  boxClassName(text) {
    let className = ''
    switch (Math.ceil(Math.log10(text + 1))) {
      case 3:
        className = ' roi'
        break
      case 4:
        className = ' pan'
        break
      case 5:
        className = ' meun'
        break
      case 6:
        className = ' san'
        break
      default:
        className = ''
    }
    return className
  }

  render() {
    return (
      <NumberBoxStyled className={this.props.className + ' text-style' + this.boxClassName(this.props.text)} id={this.props.id}>
        {this.props.text}
      </NumberBoxStyled>
    )
  }
}

NumberBox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string
}

export default NumberBox
