import styled from 'styled-components'

export default styled.div`
  min-width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.orangeFont};
  font-family: 'Prompt', sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &.roi {
    font-size: 16px;
    max-width: 50px;
  }
  &.pan {
    font-size: 16px;
    max-width: 50px;
  }
  &.meun {
    font-size: 15px;
    max-width: 50px;
  }
  &.san {
    font-size: 14px;
    max-width: 50px;
  }
`
